import React from "react";
import moment from "moment";
import momentTimezone from 'moment-timezone';
// import Notiflix from "notiflix-react";
import Notiflix from "notiflix";
// import 'moment/locale/lo'

// ກຳນົດ ເພດ
export const setGender = (SetGender) => {
  let res = "";
  switch (SetGender) {
    case "MALE":
      res = "ຊາຍ";
      break;
    case "FEMALE":
      res = "ຍິງ";
      break;
    case "OTHER":
      res = "ອື່ນໆ";
      break;
  }
  return res;
};
export const setGenders = (SetGender) => {
  let res = "";
  switch (SetGender) {
    case "FEMALE":
      res = "ນາງ";
      break;
    case "MALE":
      res = "ທ້າວ";
      break;
    case "OTHER":
      res = "ອື່ນໆ";
      break;
  }
  return res;
};
// ກຳນົດ ສະຖານະປິດເປີດ
export const setSwich = (string) => {
  let res = "";
  switch (string) {
    case true:
      res = "ເປີດ";
      break;
    case false:
      res = "ປິດ";
      break;
  }
  return res;
};

// ກຳນົດ ຄ່າເລີ່ມຕົ້ນ(ເພດ)
export const checkgender = (gender) => {
  let gd = "";
  switch (gender) {
    case "FEMALE":
      gd = "checked";
      break;
    case "MALE":
      gd = "checked";
      break;
    case "OTHER":
      gd = "checked";
      break;
  }
  return gd;
};

// ກຳນົດ ສະຖານະພາບ
export const noticeStatus = (notice) => {
  let status = "";
  switch (notice) {
    case "IMPORTANT":
      status = "ເອກະສານທີ່ສຳຄັນ";
      break;
    case "NORMAL":
      status = "ທົ່ວໄປ";
      break;
    case "NOTIC":
      status = "ແຈ້ງການ";
      break;
    case "AGREEMENT":
      status = "ຂໍ້ຕົກລົງ";
      break;
    case "REGULATION":
      status = "ລະບຽບການ";
      break;
  }
  return status;
};
// ກຳນົດ ສະຖານະພາບ
export const MaritualStatus = (maritualStatus) => {
  let status = "";
  switch (maritualStatus) {
    case "SINGLE":
      status = "ໂສດ";
      break;
    case "MARRIAGE":
      status = "ເເຕ່ງງານ";
      break;
  }
  return status;
};
// ກຳນົດ ສະຖານະການລົງທະບຽນ
export const RegStatus = (reStatus) => {
  let status = "ຍັງ";
  switch (reStatus) {
    case 1:
      status = "ແລ້ວ";
      break;
  }
  return status;
};

export const bracket = (tag) => {
  let st = "(";
  let en = ")";
  let i = st + tag + en;
  return i;
};

//  ກຳນົດ ອາຍຸ
export const age = (age) => {
  let today = new Date();
  let y = today.getFullYear();
  let dob = moment(age).format("YYYY");
  return y - dob;
};

// ກຳນົດ ຟໍແມັດເງິນ
export const currency = (value) => {
  let currencys = new Intl.NumberFormat("en-CA").format(value);
  if (value != 0) return currencys;
  else if (value == 0) return "0"
  else return ""
};

// ກຳນົດ ເວລາປັດຈຸບັນ(ພາສາລາວ)
export const formatDateTime = (dateTime) => {
  moment.locale("lo");
  let resp = moment(dateTime ).format("DD MMMM YYYY, HH:mm");
  return resp;
};

// ກຳນົດ ວັນທີປັດຈຸບັນ(ພາສາລາວ)
export const formatDate = (dateTime) => {
  console.log("dateTime:", dateTime)
  moment.locale("lo");
  let resp = moment(moment(dateTime).subtract(7, "hours")).format("DD MMMM YYYY : HH:mm");
  console.log("dateTime2:", resp)
  if (dateTime) return resp;
  else return ""
};
// ກຳນົດ ວັນທີປັດຈຸບັນ(ພາສາລາວ)
export const formatDateWithTimeZone = (dateTime) => {
  console.log("dateTime:", dateTime)
  moment.locale("lo");
  let resp = moment(momentTimezone(dateTime).tz("Asia/Vientiane")).format("DD MMMM YYYY : HH:mm");
  console.log("dateTime2:", resp)
  if (dateTime) return resp;
  else return ""
};




// ກຳນົດ ວັນທີປັດຈຸບັນ(/)
export const formateDateSlash = (slashData) => {
  let resp = moment(slashData).format("YYYY/MM/DD");
  return resp;
};

// ກຳນົດ ວັນທີປັດຈຸບັນ(-)
export const formatDateDash = (dashDate) => {
  let resp = moment(dashDate).format("YYYY-MM-DD HH:mm:ss");
  return resp;
};
export const formatDateDashH = (dashDate) => {
  console.log("dashDate", dashDate)
  const resp = moment(moment(dashDate).subtract(7, "hours")).format("YYYY-MM-DD HH:mm");
  console.log("dashDate", resp)
  return resp;
};

// ກຳນົດ ວັນທີປັດຈຸບັນ(ຖັດໄປ 1 ອາທິດ)
export const nextSevenDay = () => {
  var nextSenvenDay = moment().add(7, "days");
  var nextSevenDays = moment(nextSenvenDay).format("YYYY-MM-DD");
  return nextSevenDays;
};

// ກຳນົດ ວັນທີປັດຈຸບັນ(ຖັດໄປ 1 ມື້)
export const nextOneDay = () => {
  var nextOneDay = moment().add(1, "days");
  var nextOneDays = moment(nextOneDay).format("YYYY-MM-DD");
  return nextOneDays;
};

// ກຳນົດ ວັນທີ ແລະ ເວລາປັດຈຸບັນ(ພາສາລາວ)
export const toDay = () => {
  moment.locale("lo");
  var today = new Date();
  var todays = moment(today).format("DD/MM/YY, HH:mm");
  return todays;
};

// ກຳນົດ ວັນທີປັດຈຸບັນ(-)
export const toDayDash = () => {
  var today = new Date();
  var todays = moment(today).format("YYYY-MM-DD");
  return todays;
};
export const reconvertDay = (day) => {
  let result = "";
  switch (day) {
    case "MONDAY":
      result = "ຈັນ";
      break;
    case "TUESDAY":
      result = "ອັງຄານ";
      break;
    case "WEDNESDAY":
      result = "ພຸດ";
      break;
    case "THURSDAY":
      result = "ພະຫັດ";
      break;
    case "FRIDAY":
      result = "ສຸກ";
      break;
    case "SATURDAY":
      result = "ເສົາ";
      break;
    case "SUNDAY":
      result = "ອາທິດ";
      break;
    default:
      result = "ຈັນ";
  }
  return result;
};

export const convertLetterGrade = (numericGrade) => {
  if (numericGrade >= 80) return "A";
  else if (numericGrade >= 75) return "B+";
  else if (numericGrade >= 70) return "B";
  else if (numericGrade >= 65) return "C+";
  else if (numericGrade >= 60) return "C";
  else if (numericGrade >= 55) return "D+";
  else if (numericGrade >= 50) return "D";
  else if (numericGrade >= 0) return "F";
  else if (numericGrade.toString() == "") return "I";
  else return "";
}

// ກຳນົດ ເຄື່ອງໝາຍ ບັງຄັບໃຫ້ປ້ອນຂໍ້ມູນ
export const valiDate = () => {
  return <font style={{ color: "red" }}> * </font>;
};

// ກຳນົດ ການຈັດລຽງຕາມຄ່າຕ່າງໆ
export const SortBy = ({ sort }) => {
  return (
    <div
      onClick={() => {
        sort();
      }}
      style={{
        float: "right",
        marginRight: 5,
        cursor: "pointer",
      }}
    >
      <i className="fa fa-sort"></i>
    </div>
  );
};

export const alertSuccess = (e) => {

  return Notiflix.Report.Success(
    "ສຳເລັດ",
    " " + e + " ",
    "ອອກ",
    function () {
      window.location.reload();
    }
  );
}


export const alertWarning = (e) => {
  return Notiflix.Report.Warning(
    "ບໍ່ສຳເລັດ",
    " " + e + " ",
    "ອອກ",
    function () {
      window.location.reload();
    }
  );
};

export const alertConfirm = (action) => {
  return Notiflix.Confirm.Show('Confirm', 'ທ່າຕ້ອງການລຶບຂໍ້ມູນນີ້ແທ້ ຫຼື ບໍ່ ?', 'ຕ້ອງການ', 'ບໍ່ຕ້ອງການ',
    function () {
      action();
    },
    function () {
      window.location.reload();
    });
};

export const startLoading = () => {
  return Notiflix.Loading.Circle();
};
