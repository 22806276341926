// External libraries
import React, { useState, useEffect } from "react";
import axios from "axios";
import useReactRouter from "use-react-router";

// Formik and CKEditor
import { Formik } from "formik";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

// React Bootstrap components
import Image from "react-bootstrap/Image";

// Local constants and helper functions
import {
  TITLE_HEADER,
  HEADER,
  BODY_DISCOUNT_SHOPDETAIL,
  LIMIT,
} from "../../../consts";
import { PROMOTION_LIST } from "../../../consts/router";
import { PRESIGNED_URL, PROMOTION_CREATE } from "../../../consts/api";
import { getLocalData } from "../../../consts/tokken";
import { formatDateDash } from "../../../common/super";
import { successAdd, errorAdd } from "../../../helper/sweetalert";

// Local images and CSS
import ImageLogo from "../../../image/profile.png";
import "../utils/index.css";

export default function PromotionAdd() {
  const { history, location, match } = useReactRouter();

  // State variables
  const [getToken, setGetToken] = useState();
  const [ckEditer, setCkEditer] = useState("");
  const [file, setFile] = useState();
  const [imageLoading, setImageLoading] = useState();
  const [namePhoto, setNamePhoto] = useState("");
  const [statusData, setStatusData] = useState();
  const [typeData, _setType] = useState();
  const [blob, setBlob] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const _localData = await getLocalData();
      if (_localData) {
        setGetToken(_localData);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {

      // setStatusData(location.state.status);
   console.log("statusData", statusData);
  }, [statusData]);

  // lung jak upload leo pic ja ma so u nee
  const ImageThumb = ({ image }) => {
    return (
      <img
        src={blob}
        alt={image.name}
        style={{
          width: "100%",
          height: "auto",
          objectFit: "cover",
        }}
      />
    );
  };

  const handleUpload = async (event) => {
    const file = event.target.files[0];
    setImageLoading("");
    setFile(file);
    setBlob(URL.createObjectURL(file));

    try {
      const responseUrl = await axios.post(PRESIGNED_URL, {
        headers: getToken,
        data: {
          type: file.type,
        },
      });

      setNamePhoto(responseUrl.data);

      await axios.put(responseUrl.data.url, file, {
        headers: {
          "Content-Type": " file/*; image/*",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
          "Access-Control-Allow-Headers":
            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setImageLoading(percentCompleted);
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const _createData = async (values) => {
    const newStatusData = statusData ? "OPEN" : "CLOSED";
    console.log("newStatusData", newStatusData);
    try {
      await axios
        .post(
          PROMOTION_CREATE,
          {
            title: values.title,
            detail: ckEditer,
            startDate: formatDateDash(values.startDate),
            endDate: formatDateDash(values.endDate),
            status: newStatusData,
            image: namePhoto?.filename,
            note: values.note,
          },
          {
            headers: getToken,
          }
        )
        .then(() => {
          successAdd();
          setTimeout(() => {
            history.push(`${PROMOTION_LIST}/limit/${LIMIT}/page/1/`);
          }, 1800);
        });
    } catch (error) {
      errorAdd("Something went wrong, please try again !");
    }
  };

  const navigateToPromotionList = () => {
    history.push(`${PROMOTION_LIST}/limit/${LIMIT}/page/1/`);
  };

  return (
    <div style={TITLE_HEADER}>
      <div style={HEADER}>
        <button className="link-button" onClick={navigateToPromotionList}>
          All Promotions »
        </button>{" "}
        <span>Add Promotions</span>
      </div>
      <div style={{ height: 20 }}></div>

      <Formik
        initialValues={{
          title: "",
          detail: "",
          status: "",
          startDate: "",
          endDate: "",
          note: "",
        }}
        validate={(values) => {
          const errors = {};

          if (
            !values.title ||
            values.title.trim() === "" ||
            values.title.startsWith(" ")
          ) {
            errors.title = "Required";
          }

          if (!ckEditer) {
            errors.detail = "Required";
          }

          if (!values.startDate) {
            errors.startDate = "Required";
          }

          if (!values.endDate) {
            errors.endDate = "Required";
          }

          if (
            values.startDate &&
            values.endDate &&
            values.startDate > values.endDate
          ) {
            errors.startDate = "Start date must be before end date";
            errors.endDate = "End date must be after start date";
          }

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          _createData(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <div style={BODY_DISCOUNT_SHOPDETAIL}>
              <div style={{ height: 100 }}></div>
              <div className="row">
                <div className="col-md-4 center">
                  <input
                    type="file"
                    id="file-upload"
                    onChange={handleUpload}
                    hidden
                  />
                  <label for="file-upload">
                    <div
                      style={{
                        backgroundColor: "#E4E4E4E4",
                        height: 200,
                        width: 300,
                        borderRadius: "5%",
                        cursor: "pointer",
                        overflow: "hidden",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {blob ? (
                        <ImageThumb image={file} />
                      ) : (
                        <div
                          style={{
                            height: 200,
                            width: 300,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "5%",
                          }}
                        >
                          <p
                            style={{
                              color: "#fff",
                              fontSize: 80,
                              fontWeight: "bold",
                            }}
                          >
                            +
                          </p>
                        </div>
                      )}
                    </div>
                  </label>
                  {/* progass */}
                  {imageLoading ? (
                    <div className="progress" style={{ height: 20 }}>
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{
                          width: `${imageLoading}%`,
                          backgroundColor: "#816aae",
                        }}
                        aria-valuenow={imageLoading}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        {imageLoading}%
                      </div>
                    </div>
                  ) : (
                    <div style={{ height: 20 }} />
                  )}
                </div>
                <div style={{ height: 50 }}></div>
                <div className="col-md-8">
                  <div style={{ flexDirection: "row", display: "flex" }}>
                    <div style={{ fontWeight: "bold" }}>Title </div>
                    <div style={{ color: "red", fontWeight: "bold" }}> *</div>
                  </div>
                  <input
                    type="text"
                    name="title"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.title}
                    placeholder="ໂປຣໂມຊັນເຄື່ອງໃຊ້ໄຟຟ້າ..."
                    className="form-control col-9"
                  />
                  <div style={{ color: "red" }}>
                    {errors.title && touched.title && errors.title}
                  </div>
                  <div style={{ height: 20 }}></div>
                  <div style={{ flexDirection: "row", display: "flex" }}>
                    <div style={{ fontWeight: "bold" }}>Promotion details </div>
                    <div style={{ color: "red", fontWeight: "bold" }}> *</div>
                  </div>
                  <div
                    className="col-9"
                    style={{
                      padding: "0",
                    }}
                  >
                    <CKEditor
                      editor={ClassicEditor}
                      data=""
                      onChange={(event, editor) => {
                        setCkEditer(editor.getData());
                      }}
                      config={{
                        placeholder: "ກະລຸນາປ້ອນ...",
                      }}
                    />
                  </div>
                  <div style={{ color: "red" }}>
                    {errors.detail && touched.detail && errors.detail}
                  </div>
                  <div style={{ height: 20 }}></div>
                  <div className="row">
                    <div className="col-md-4">
                      <div style={{ flexDirection: "row", display: "flex" }}>
                        <div style={{ fontWeight: "bold" }}>Start time</div>
                        <div style={{ color: "red", fontWeight: "bold" }}>
                          {" "}
                          *
                        </div>
                      </div>
                      <input
                        type="datetime-local"
                        name="startDate"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.startDate}
                        placeholder="ກະລຸນາປ້ອນ..."
                        className="form-control col-10"
                      />
                      <div style={{ color: "red" }}>
                        {errors.startDate &&
                          touched.startDate &&
                          errors.startDate}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div style={{ flexDirection: "row", display: "flex" }}>
                        <div style={{ fontWeight: "bold" }}>End time</div>
                        <div style={{ color: "red", fontWeight: "bold" }}>
                          {" "}
                          *
                        </div>
                      </div>
                      <input
                        type="datetime-local"
                        name="endDate"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.endDate}
                        placeholder="ກະລຸນາປ້ອນ..."
                        className="form-control col-10"
                      />
                      <div style={{ color: "red" }}>
                        {errors.endDate && touched.endDate && errors.endDate}
                      </div>
                    </div>
                  </div>
                  <div style={{ height: 20 }}></div>
                  <div>
                    <div>
                      <p style={{ margin: 0, fontWeight: "bold" }}>
                        Publishing status
                      </p>
                      <label className="switch">
                        <input
                          type="checkbox"
                          name="status"
                          // onChange={handleChange}
                          onChange={() => {
                            // handleChange();
                            setStatusData(!statusData);
                            console.log("=======>", statusData);
                          }}
                          onBlur={handleBlur}
                          className="form-control col-9"
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </div>
                  <div style={{ height: 20 }}></div>
                  <div>
                    <p style={{ margin: 0, fontWeight: "bold" }}>Remark</p>
                    <textarea
                      id="note"
                      name="note"
                      rows="4"
                      value={values.note}
                      onChange={handleChange}
                      cols="50"
                      className="form-control col-9"
                    ></textarea>
                  </div>
                  <div style={{ height: 20 }}></div>
                  <div
                    className="center col-9"
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <button
                      type="button"
                      className="btn btn-secondary col-4"
                      style={{
                        border: "solid 1px #606060",
                        color: "#606060",
                        backgroundColor: "#ffff",
                      }}
                      onClick={navigateToPromotionList}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn btn-secondary col-4"
                      style={{ backgroundColor: "#816AAE" }}
                    >
                      Confirm
                    </button>
                  </div>
                  <div style={{ height: 20 }}></div>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}
